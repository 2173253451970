import './App.css';
import { Portfolio } from './components/Portfolio';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.css'
function App() {
  return (
    <div className="App">
      <Portfolio/>
    </div>
  );
}

export default App;
