import React from "react";
import facebook from "../images/social/facebook.svg"
import instagram from "../images/social/instagram.svg"
import linkedin from "../images/social/linkedin.svg"
import github from "../images/social/github.svg"

export const Social = () => {
    return (
        
            <div className="social-link-container">
                <SocialIcon height={30} width={30} src={facebook}/>
                <SocialIcon height={30} width={30} url="https://www.instagram.com/itsjaypatel17/" src={instagram}/>
                <SocialIcon height={30} width={30} url="https://www.linkedin.com/in/itsjaypatel/" src={linkedin}/>
                <SocialIcon height={30} width={30} url="https://www.github.com/itsjaypatel/" src={github}/>
            </div>
        
    );
}

export const SocialIcon = ({src,url="#",width,height}) =>
(
    <a href={url} aria-hidden="true"><img className="social-icon" src={src} width={width} height={height} alt={src}></img></a>
);